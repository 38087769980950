 const baseUrl = 'https://api.cdchost.com.br/api';

const request = async (method, endpoint, params, token = null) => {
    method = method.toLowerCase();
    let fullUrl = `${baseUrl}${endpoint}`;
    let body = null;
    switch(method) {
        case 'get':
            let queryString = new URLSearchParams(params).toString();
            fullUrl += `?${queryString}`;
            break;
            case 'post':
            case 'put':
            case 'delete':
                body = JSON.stringify(params);
            break;
    }
    let headers = {'Content-Type': 'application/json'};
    if(token) {
        headers.Authorization = `Bearer ${token}`;
    }
    let req = await fetch(fullUrl, {method, headers, body});
    let json = await req.json();
    return json;
}
 export default () => {
     return {
         getToken: () => {
             return localStorage.getItem('token');
         },
         validateToken: async () => {
             let token = localStorage.getItem('token');
             let json = await request('post', '/auth/validate', {}, token);
             return json;
         },
         addUser: async (data) => {
            let json = await request('post', '/contact', data, 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmNkY2hvc3QuY29tLmJyXC9hcGlcL2FkbWluXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYzNDA0NzcyOCwibmJmIjoxNjM0MDQ3NzI4LCJqdGkiOiJCUklzSmdsWHlCSWp6V3NxIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.rlFfyBxDigcXu_D55UVAdh3RH1nS0Ii3m-Zl4Qk1_Tk');
            return json;
         }

     }
 }
